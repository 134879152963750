import React from 'react'
import { RecipientFilter, ServiceFilter, VariableButton } from './filters'
import { DateRangeButton } from './daterange'
import { FiltersPreviewButton, FiltersCollapse } from './filterspreview'


const Header = () => (
  <div className='planning-header'>
    <div className="form-inline pb-2 planning-variable-filter">
      <DateRangeButton />
      <ServiceFilter />
      <RecipientFilter />
      <VariableButton />
      <FiltersPreviewButton />
    </div>
    <div>
      <FiltersCollapse />
    </div>
  </div>
)

export default Header
