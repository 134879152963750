import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { initAjaxSelectpicker } from 'components/form/utils'

const RecipientFilter = () => {
  const { state } = useLocation()
  const ref = useRef()
  const navigate = useNavigate()
  const [recipients, setRecipients] = useState([])

  const onChange = event => {
    const { selectedOptions } = event.target
    const recipientIds = Array.from(selectedOptions).map(({ value }) => value)

    navigate(null, { state: { ...state, recipientIds, page: 1 } })
  }

  useEffect(() => {
    const ajax = { url: '/contacts', title: 'Tous les destinataires' }

    initAjaxSelectpicker(ref.current, ajax)
  }, [])

  const fetchRecipients = async() => {
    const response = await fetch(`/contacts?id=${  state.recipientIds.join('-')}`)
    const data = await response.json()

    setRecipients(data)
    navigate(null, { state: { ...state, recipients: data, page: 1 } })
  }

  useEffect(() => {
    if (state?.recipientIds)
      fetchRecipients()
  }, [state?.recipientIds])

  useEffect(() => {
    if (ref.current) {
      const selectElement = ref.current

      recipients.forEach(recipient => {
        const option = document.createElement('option')
        option.value = recipient.value
        option.text = recipient.text
        option.selected = true
        selectElement.appendChild(option)
      })

      $(ref.current).selectpicker('val', state?.recipientIds)
      $(ref.current).trigger('change')
      $(ref.current).on('changed.bs.select', onChange)
    }

    return () => {
      if (ref.current)
        $(ref.current).off('changed.bs.select', onChange)
    }
  }, [recipients, onChange, state?.recipientIds])

  return (
    <div className='form-group ml-2'>
      <select
        ref={ref}
        className='planning-dropdown w-100'
        id='recipientIds'
        data-actions-box="true"
        multiple
      />
    </div>
  )
}

export default RecipientFilter
