import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from 'components'

const IconButton = ({
  type = 'primary',
  title,
  text = '',
  iconName,
  className,
  onClick
}) => {
  const btnClasses = classNames('btn', `btn-${type}`, className)
  const iconClasses = classNames({ 'mr-2': Boolean(text) })

  return (
    <button className={btnClasses} title={title} type="button" onClick={onClick}>
      <Icon name={iconName} className={iconClasses} />
      {text}
    </button>
  )
}

IconButton.propTypes = {
  className: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string
}

export default IconButton
