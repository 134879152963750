import PropTypes from 'prop-types'

const selectConfig = { liveSearch: true }

const ajaxSelectConfig = (url, emptyTitle) => ({
  ajax: {
    url,
    type: 'GET',
    dataType: 'json',
    data: { q: '{{{q}}}' } // eslint-disable-line id-length
  },
  langCode: 'fr-FR',
  preserveSelected: true,
  locale: {
    emptyTitle
  }
})

const initSelectpicker = node => $(node).selectpicker(selectConfig)

const initAjaxSelectpicker = (node, ajax) => {
  initSelectpicker(node).ajaxSelectPicker(ajaxSelectConfig(ajax.url, ajax.title))
}

const flatpickerOpts = {
  plugins: [new confirmDatePlugin({})], // eslint-disable-line new-cap
  altInput: true,
  altFormat: 'd/m/Y H:i',
  dateFormat: 'Y-m-d H:i',
  enableTime: true,
  time_24hr: true,
  allowInput: true
}

const initFlatpickr = (ref, onClose) => {
  $(ref.current).flatpickr($.extend(flatpickerOpts, { onClose }))
}

const formikPropTypes = PropTypes.exact({
  dirty: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isValidating: PropTypes.bool.isRequired,
  submitCount: PropTypes.number.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialTouched: PropTypes.object.isRequired,
  validateOnBlur: PropTypes.bool.isRequired,
  validateOnChange: PropTypes.bool.isRequired,
  validateOnMount: PropTypes.bool.isRequired,
  status: PropTypes.any,
  initialStatus: PropTypes.any,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setFormikState: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  validateField: PropTypes.func.isRequired,
  unregisterField: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  getFieldProps: PropTypes.func.isRequired,
  getFieldMeta: PropTypes.func.isRequired,
  getFieldHelpers: PropTypes.func.isRequired
})

export {
  initSelectpicker,
  initAjaxSelectpicker,
  initFlatpickr,
  formikPropTypes
}
