import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { AgendaContext } from './contexts'
import router from './router'

const Agenda = ({
  currentUserId,
  currentUserRole = 'operator',
  currentAgendaLabel,
  users = '{}',
  services = '{}'
}) => {
  const data = useMemo(() => ({
    currentUserId,
    currentUserRole,
    currentAgendaLabel,
    users: JSON.parse(users),
    services: JSON.parse(services)
  }), [])

  return (
    <AgendaContext.Provider value={data}>
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        theme="colored"
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AgendaContext.Provider>
  )
}

Agenda.propTypes = {
  currentAgendaLabel: PropTypes.oneOf(['service', 'recipient']).isRequired,
  currentUserId: PropTypes.number.isRequired,
  currentUserRole: PropTypes.oneOf(['operator', 'admin', 'staff']),
  services: PropTypes.string,
  users: PropTypes.string
}

export default Agenda
