import React from 'react'
import { Period, CustomPeriod, RelativePeriod } from './DateRangeListContent'

const DateRangeList = () => (
  <>
    <Period />
    <hr />
    <CustomPeriod />
    <hr />
    <RelativePeriod />
  </>
)

export default DateRangeList
