import React from 'react'
import { Icon } from 'components'
import { Header, PlanningTable, ExportButton } from '.'

const Planning = () => (
  <div className='operational-planning'>
    <div className='pb-2'>
      <h1 className="d-inline">
        <span className="pr-2">
          <Icon name="calendar-alt" />
        </span>
        <span>Planning opérationnel</span>
      </h1>
      <ExportButton />
    </div>
    <div className='card p-3 mt-3'>
      <Header />
      <PlanningTable />
    </div>
  </div>
)

export default Planning
