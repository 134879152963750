import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'

const RelativePeriodLink = ({ linkText, children }) => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate(null, { state: { ...state, modalOpenWith: linkText } })
  }, [])

  return (
    <a
      href="#"
      className="dropdown-item"
      data-toggle="modal"
      data-target="#date-picker-modal"
      onClick={handleClick}
    >
      {children}
    </a>
  )
}

RelativePeriodLink.propTypes = {
  children: PropTypes.node.isRequired,
  linkText: PropTypes.string.isRequired
}

const RelativePeriod = () => (
  <>
    <RelativePeriodLink linkText="on">Le ...</RelativePeriodLink>
    <RelativePeriodLink linkText="before">Avant le ...</RelativePeriodLink>
    <RelativePeriodLink linkText="after">Après le ...</RelativePeriodLink>
  </>
)

export default RelativePeriod
