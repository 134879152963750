const daterangepickerLocale = {
  format: 'DD/MM/YYYY',
  applyLabel: 'Valider',
  cancelLabel: 'Annuler',
  daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  monthNames: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ]
}

export { daterangepickerLocale }
