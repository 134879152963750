import moment from 'moment'

const serviceStructure = element => ({
  id: element.id,
  name: element.name,
  color: element.color
})

const recipientStructure = element => ({
  id: element.id,
  name: `${element.last_name.toUpperCase()  } ${  element.first_name}`,
  list_id: element.list_id
})

const planningStructure = res => {
  const services = res.services.map(serviceStructure)
  const recipients = res.recipients.map(recipientStructure)
  const { appointments } = res
  const pagination = res?.pagination

  return { services, recipients, appointments, pagination }
}

const formatDate = date => date ? moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY') : null

const formatIds = ids => ids ? ids.join('-') : null

const formatUrl = values => values ? new URLSearchParams(values).toString() : null

const formatParams = data => {
  const params = new URLSearchParams()
  const obj = {
    range_start: formatDate(data?.rangeStart),
    range_end: formatDate(data?.rangeEnd),
    recipient_ids: formatIds(data?.recipientIds),
    service_ids: formatIds(data?.serviceIds),
    variable_values: formatUrl(data?.variableValues)
  }

  Object.entries(obj).forEach(([key, val]) => val ? params.append(key, val) : null)

  return params.toString()
}

export { planningStructure, formatParams }
