import React from 'react'
import magnifyingGlass from 'images/icons/magnifying-glass.svg'

const NoAppointmentItem = () => (
  <div className='d-flex justify-content-md-center align-items-center no-appointments'>
    <div className="d-flex justify-content-center pt-2">
      <img className="report-no-data-icon" src={magnifyingGlass} />
    </div>
    <div className="d-flex justify-content-center report-no-data-title pt-2">
      Aucun rendez-vous
    </div>
  </div>
)

export default NoAppointmentItem
