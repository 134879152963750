import React, { useEffect, useRef, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Icon } from 'components'
import { initAjaxSelectpicker } from 'components/form/utils'
import PropTypes from 'prop-types'

const VariableValueFilter = ({ variableDetail }) => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const ref = useRef()

  const variableValues = state?.variableValues ?? {}
  const variableValuesCount = variableValues[variableDetail.id]?.length || 0
  const variableString = `Variable : ${variableDetail.name} (${variableValuesCount})`

  const onChange = useCallback(({ target: { selectedOptions } }) => {
    const variableValue = Array.from(selectedOptions).map(({ value }) => value)
    const updatedValues = { ...variableValues, [variableDetail.id]: variableValue }

    navigate(null, { state: { ...state, variableValues: updatedValues, page: 1 } })
  }, [variableValues, variableDetail.id, navigate])

  useEffect(() => {
    if (ref.current) {
      const ajax = { url: `/variables/${variableDetail.id}/values.json`, title: 'Tous les destinataires' }

      initAjaxSelectpicker(ref.current, ajax)
    }
  }, [variableDetail.id])

  const handleRemove = id => {
    const updatedValues = { ...variableValues }
    delete updatedValues[id]
    const uniqueVariableIds = Object.keys(updatedValues)

    navigate(null, { state: {
      ...state,
      variableValues: updatedValues,
      variableIds: uniqueVariableIds,
      page: 1
    } })
  }

  const handleRemoveCallback = useCallback(() => {
    handleRemove(variableDetail.id)
  }, [handleRemove, variableDetail.id])

  useEffect(() => {
    if (ref.current) {
      const selectElement = ref.current
      const values = variableValues[variableDetail.id] || []

      values.forEach(value => {
        const option = document.createElement('option')
        option.value = value
        option.text = value
        option.selected = true
        selectElement.appendChild(option)
      })

      $(ref.current).trigger('change')
      $(ref.current).on('changed.bs.select', onChange)

      return () => {
        if (ref.current)
          $(ref.current).off('changed.bs.select', onChange)
      }
    }

    return null
  }, [variableValues, variableDetail.id, onChange])

  return (
    <div className="planning-variable-value-filter w-100 py-2 w-100">
      <div className="form-group">
        <label className="planning-variable-label d-block" htmlFor="select-filter-1">
          {variableString}
        </label>
        <span className="pl-2" onClick={handleRemoveCallback}>
          <Icon name="trash" />
        </span>
        <div className='pt-2 w-100'>
          <select
            ref={ref}
            className="w-100 planning-variable-value-filter"
            data-actions-box="true"
            multiple
          />
        </div>
      </div>
    </div>
  )
}

VariableValueFilter.propTypes = {
  variableDetail: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
}

export default VariableValueFilter
