import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { formatParams } from 'pages/plannings/utils/planning'

const ExportButton = () => {
  const { state } = useLocation()
  const params = useMemo(() => formatParams(state), [state])
  const url = `/planning.xlsx?${params}`

  return (
    <a href={url} className="btn btn-primary float-right mt-1">
      <span>Exporter</span>
    </a>
  )
}

export default ExportButton
