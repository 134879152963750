import React from 'react'
import PropTypes from 'prop-types'
import { Tablehead } from './tableheadcomponent'
import { Tablebody } from './tablebodycomponent'
import NoAppointmentItem from './NoAppointmentItem'

const Table = ({ planningData }) => {
  const hasAppointments = Object.keys(planningData.recipients).length > 0

  return hasAppointments ? (
    <table className='table table-bordered mt-2'>
      <Tablehead services={planningData.services} />
      <Tablebody planningData={planningData} />
    </table>
  ) :(
    <NoAppointmentItem />
  )
}

Table.propTypes = {
  planningData: PropTypes.shape({
    recipients: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired,
    services: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
}

export default Table
