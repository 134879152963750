import React from 'react'
import { VariableModal } from './variableComponent'
import { Icon } from 'components'

const VariableButton = () => (
  <>
    <button
      type='button'
      className='btn btn-white planning-variable-button ml-2'
      data-toggle='modal'
      data-target='#variable-modal'
    >
      <Icon name="plus" />
      {' '}
      Ajouter une variable
    </button>
    <VariableModal />
  </>
)

export default VariableButton
