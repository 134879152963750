import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import  { Icon } from 'components'
import PropTypes from 'prop-types'

const VariableFiltersPreviewItem = ({ item, variableDetail }) => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const handleRemove = useCallback(() => {
    const variableValues = state?.variableValues[variableDetail.id].filter(value => value !== item) ?? {}
    const updatedVariableValue = state?.variableValues
    updatedVariableValue[variableDetail.id] = variableValues

    navigate(null, { state: { ...state, variableValues: updatedVariableValue } })
  }, [navigate, state])

  const handleRemoveCallback = useCallback(() => {
    handleRemove()
  }, [state])

  return (
    <a className="report-label-list-item rounded" onClick={handleRemoveCallback}>
      {item}
      <span className="pl-1">
        <Icon name="times" />
      </span>
    </a>
  )
}

VariableFiltersPreviewItem.propTypes = {
  item: PropTypes.string.isRequired,
  variableDetail: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
}

export default VariableFiltersPreviewItem
