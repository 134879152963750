import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import AppointmentItem from './AppointmentItem'

const defaultAppointments = {}

const AppointmentCell = ({ appointments = defaultAppointments }) => {
  const ref = useRef()
  const [isButtonVisible, setIsButtonVisible] = useState(true)

  const appointmentKeys = Object.keys(appointments ?? {})
  const appointment = appointments[appointmentKeys[0]]

  const handleButtonClick = useCallback(event => {
    event.preventDefault()
    setIsButtonVisible(false)
  }, [])

  return (
    <div ref={ref}>
      <div className='pb-2' key={appointmentKeys[0]}>
        <AppointmentItem key={appointment.id} appointment={appointment} />
      </div>
      {appointmentKeys.length > 1 && (
        <>
          {isButtonVisible ? (
            <div className="d-flex justify-content-center w-100 pb-2">
              <button
                type='button'
                key={`button-${appointment.id}`}
                className="btn btn-link border w-100"
                data-target={`#toggleAppointment-${appointment.recipient_id}-${appointment.service_id}`}
                data-toggle="collapse"
                onClick={handleButtonClick}
              >
                {`Tout voir (${appointmentKeys.length - 1})`}
              </button>
            </div>
          ) : null}
          <div
            className="collapse"
            id={`toggleAppointment-${appointment.recipient_id}-${appointment.service_id}`}
          >
            {appointmentKeys.slice(1).map(key => (
              <div className='pb-2' key={key}>
                <AppointmentItem key={appointments[key].id} appointment={appointments[key]} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

AppointmentCell.propTypes = {
  appointments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      recipient_id: PropTypes.number.isRequired,
      service_id: PropTypes.number.isRequired
    })
  )
}

export default AppointmentCell
