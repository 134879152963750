import React, { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

const Pagination = ({ page, pages, onPageChange }) => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const handlePageChange = useCallback(newPage => {
    if (newPage >= 1 && newPage <= pages) {
      onPageChange(newPage)
      navigate(null, { state: { ...state, page: newPage } })
    }
  }, [navigate, onPageChange, pages, state])

  const handlePageClick = useCallback(index => () => {
    handlePageChange(index)
  }, [handlePageChange])

  const handlePrevClick = useCallback(() => {
    handlePageChange(page - 1)
  }, [handlePageChange, page])

  const handleNextClick = useCallback(() => {
    handlePageChange(page + 1)
  }, [handlePageChange, page])

  const renderPageNumbers = useCallback(() => {
    const pageNumbers = []
    const maxPageNumbersToShow = 5
    const halfMaxPageNumbersToShow = Math.floor(maxPageNumbersToShow / 2)

    let startPage = Math.max(1, page - halfMaxPageNumbersToShow)
    let endPage = Math.min(pages, page + halfMaxPageNumbersToShow)

    if (page <= halfMaxPageNumbersToShow)
      endPage = Math.min(pages, maxPageNumbersToShow)
    else if (page + halfMaxPageNumbersToShow >= pages)
      startPage = Math.max(1, pages - maxPageNumbersToShow + 1)


    if (startPage > 1) {
      pageNumbers.push(
        <button
          type="button"
          key={1}
          className={`pagy-page ${page === 1 ? 'active' : ''}`}
          onClick={handlePageClick(1)}
        >
          1
        </button>
      )
      if (startPage > 2)
        pageNumbers.push(<span key="start-ellipsis" className="pagy-page pagy-ellipsis">...</span>)
    }

    for (let index = startPage; index <= endPage; index += 1) {
      pageNumbers.push(
        <button
          type="button"
          key={index}
          className={`pagy-page ${index === page ? 'active' : ''}`}
          onClick={handlePageClick(index)}
        >
          {index}
        </button>
      )
    }

    if (endPage < pages) {
      if (endPage < pages - 1)
        pageNumbers.push(<span key="end-ellipsis" className="pagy-page pagy-ellipsis">...</span>)

      pageNumbers.push(
        <button
          type="button"
          key={pages}
          className={`pagy-page ${page === pages ? 'active' : ''}`}
          onClick={handlePageClick(pages)}
        >
          {pages}
        </button>
      )
    }

    return pageNumbers
  }, [handlePageClick, page, pages])

  return (
    <div className="pagy-pagination">
      <button
        type="button"
        className="pagy-prev"
        disabled={page === 1}
        onClick={handlePrevClick}
      >
        Précédent
      </button>
      {renderPageNumbers()}
      <button
        type="button"
        className="pagy-next"
        disabled={page === pages}
        onClick={handleNextClick}
      >
        Suivant
      </button>
    </div>
  )
}

Pagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired
}

export default Pagination