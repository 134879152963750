import React, { useContext, useState, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Icon } from 'components'
import { PlanningContext } from 'pages/plannings/contexts'
import VariableValueFilter from './VariableValueFilter'

const VariableDetailFilter = () => {
  const { variableDetails } = useContext(PlanningContext)
  const { state } = useLocation()
  const navigate = useNavigate()

  const variableValues = state?.variableValues ?? {}
  const variablesArray = Object.entries(variableDetails)
  const variableIds = state?.variableIds || []

  const [selectedValue, setSelectedValue] = useState(variableIds[0])

  const handleChange = useCallback(event => {
    setSelectedValue(event.target.value)
  }, [selectedValue])

  const handleClick = useCallback(() => {
    const uniqueVariableIds = new Set(variableIds)

    if (typeof selectedValue !== 'undefined')
      uniqueVariableIds.add(selectedValue)

    const updatedVariableIds = Array.from(uniqueVariableIds)

    variableValues[selectedValue] = []

    navigate(null, { state: { ...state, variableIds: updatedVariableIds, variableValues, page: 1 } })
  }, [selectedValue, variableIds, variableValues])

  return (
    <div className='form-inline w-100 planning-variable-filter pb-3'>
      <select
        value={selectedValue}
        className='form-control selectpicker'
        title="Toutes les variables"
        onChange={handleChange}
      >
        {variablesArray.map(([_id, variableDetail]) => (
          <option value={variableDetail.id} key={variableDetail.id}>{variableDetail.name}</option>
        ))}
      </select>
      <button
        className="btn btn-primary text-center w-20"
        type="button"
        onClick={handleClick}
      >
        <Icon name="plus" />
        {' '}
        Ajouter
      </button>
      {variableIds.map(id => {
        if (typeof id !== 'undefined') {
          const variableDetail = variableDetails.find(variable => variable.id === parseInt(id, 10))

          return <VariableValueFilter key={variableDetail.id} variableDetail={variableDetail} />
        }
        return ''
      })}
    </div>
  )
}

export default VariableDetailFilter
