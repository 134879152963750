import React from 'react'

const Loader = () => (
  <div className="d-flex align-items-center page-content-height">
    <div className="align-middle text-center w-100">
      <h4>Veuillez patienter...</h4>
    </div>
  </div>
)

export default Loader