import React, { useContext, useState, useMemo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { PlanningContext } from 'pages/plannings/contexts'
import PropTypes from 'prop-types'
import eyesOff from 'images/icons/eyes_off.svg'
import eyesOn from 'images/icons/eyes_on.svg'

const FiltersPreviewButton = () => {
  const data = useContext(PlanningContext)
  const { state } = useLocation()
  const [isCollapsed, setIsCollapsed] = useState(false)

  const recipientIds = state?.recipientIds ?? []
  const selectedVariableValues = state?.variableValues ?? []
  const selectedServices = state?.serviceIds ?? []

  const matchingVariables = useMemo(() => data.variableDetails?.filter(variable =>
    Object.keys(selectedVariableValues).includes(variable.id.toString())
  ) ?? [], [data.variableDetails, selectedVariableValues])

  const variables = useMemo(() => matchingVariables.map(variable => {
    const value = selectedVariableValues[variable.id]
    return value && value.length > 0 ? [variable, value] : null
  }).filter(item => item !== null), [matchingVariables, selectedVariableValues])

  const shouldDisplayBool = selectedServices.length > 0 || recipientIds.length > 0 || variables.length > 0
  const shouldDisplayLink = useMemo(() => shouldDisplayBool, [selectedServices, recipientIds, variables])

  const toggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed)
    const iconElement = document.getElementById('filter-summary-icon-on')
    if (iconElement)
      iconElement.src = isCollapsed ? eyesOff : eyesOn
  })

  const iconUrl = isCollapsed ? eyesOff : eyesOn

  return (
    <div className="form-group ml-2 planning-filter-summary-button">
      {shouldDisplayLink ? (
        <a
          aria-controls="filter-summary-collapse"
          aria-expanded={!isCollapsed}
          className="planning-filter-summary-button"
          data-toggle="collapse"
          href='#filter-summary-collapse'
          role="button"
          style={{ cursor: 'pointer' }}
          onClick={toggleCollapse}
        >
          <img
            id="filter-summary-icon-on"
            src={iconUrl}
            alt="Icon"
          />
          <span
            className="planning-filter-summary-label pl-1"
            id="report-filter-summary-label"
          >
            {isCollapsed ? 'Cacher les variables' : 'Afficher les variables'}
          </span>
        </a>
      ) : null}
    </div>
  )
}

FiltersPreviewButton.propTypes = {
  state: PropTypes.shape({
    recipientIds: PropTypes.arrayOf(PropTypes.string),
    serviceIds: PropTypes.arrayOf(PropTypes.string),
    variableValues: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ])
    )
  })
}

export default FiltersPreviewButton
