import React, { useCallback } from 'react'

const CustomPeriod = () => {
  const onClick = useCallback(() => {
    document.getElementById('date_range').click()
  }, [])

  return (
    <a className="dropdown-item" href="#" onClick={onClick}>
      Période personnalisée
    </a>
  )
}

export default CustomPeriod
