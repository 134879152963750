import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import FiltersPreviewItem from './filtersPreviewItem'

const FiltersPreview = ({ data, stateValue, title }) => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const removeFilterVariable = useCallback(() => {
    navigate(null, { state: { ...state, [stateValue]: [] } })
  }, [])

  return (
    <div className="pb-2">
      <div className="report-filter-summary-title pb-2 d-flex">
        <div className="mr-auto">
          <span>{title}</span>
        </div>
        <span className="report-remove-variable">
          <a onClick={removeFilterVariable}>
            Supprimer
          </a>
        </span>
      </div>
      <div className="border rounded py-1 px-2">
        {Object.entries(data).map(([key, item]) => (
          <FiltersPreviewItem key={key} item={item} stateValue={stateValue} />
        ))}
      </div>
    </div>
  )
}

FiltersPreview.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  stateValue: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default FiltersPreview
