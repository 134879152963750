import React from 'react'
import PropTypes from 'prop-types'

const RecipientItem = ({ recipient }) => (
  <a href={`/lists/${recipient.list_id}/contacts/${recipient.id}`}>
    {recipient.name}
  </a>
)

RecipientItem.propTypes = {
  recipient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    list_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
}

export default RecipientItem
