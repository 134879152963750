import React, { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Icon } from 'components'
import PropTypes from 'prop-types'
import VariableFiltersPreviewItem from './variableFiltersPreviewItem'

const VariableFiltersPreview = ({ variable }) => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const [variableDetail, _variableValues] = variable

  const removeFilterVariable = () => {
    const updatedVariableIds = state?.variableIds.filter(id => id !== variableDetail.id.toString()) ?? []
    const updatedVariableValues = { ...state?.variableValues }
    delete updatedVariableValues[variableDetail.id]

    navigate(null, {
      state: {
        ...state,
        variableIds: updatedVariableIds,
        variableValues: updatedVariableValues
      }
    })
  }

  const revomoveFilterVariableCallback = useCallback(() => {
    removeFilterVariable()
  }, [])

  return (
    <div className="pb-2">
      <div className="report-filter-summary-title pb-2 d-flex">
        <div className="mr-auto">
          <span>
            {`Filtre par : ${variableDetail.name}`}
          </span>
          <a data-toggle="modal" data-target="#variable-modal" type="button">
            <Icon name="pen-to-square" className="pl-1 c-pointer" />
          </a>
        </div>
        <span className="report-remove-variable">
          <a onClick={revomoveFilterVariableCallback}>
            Supprimer
          </a>
        </span>
      </div>
      <div className="border rounded py-1 px-2">
        {variable[1].map(item => (
          <VariableFiltersPreviewItem key={item} item={item} variableDetail={variableDetail} />
        ))}
      </div>
    </div>
  )
}

VariableFiltersPreview.propTypes = {
  variable: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired,
      PropTypes.arrayOf(PropTypes.string).isRequired
    ])
  ).isRequired
}

export default VariableFiltersPreview
