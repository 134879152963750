import React, { useContext } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { PlanningContext } from 'pages/plannings/contexts'

const Period = () => {
  const data = useContext(PlanningContext)
  const { state } = useLocation()
  const dateRangesArray = Object.entries(data.dateRange)

  return dateRangesArray.map(([id, dateRange], index) => (
    <div key={id}>
      {Object.entries(dateRange).map(([_id, range]) => (
        <Link
          key={range.start}
          className="dropdown-item"
          state={{ ...state, rangeStart: range.start, rangeEnd: range.end, rangeTitle: range.title }}
        >
          {range.title}
        </Link>
      ))}
      {index < dateRangesArray.length - 1 && <hr />}
    </div>
  ))
}

export default Period
