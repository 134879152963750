import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { FiltersPreview, VariableFiltersPreview } from './filterspreviewcomponent'

import { PlanningContext } from 'pages/plannings/contexts'

const FiltersCollapse = () => {
  const data = useContext(PlanningContext)
  const { state } = useLocation()

  const selectedRecipients = state && state.recipients ? state.recipients : []
  const recipientIds = state && state.recipientIds ? state.recipientIds : []

  const selectedVariableValues = state && state.variableValues ? state.variableValues : []
  const selectedServices = state && state.serviceIds ? state.serviceIds : []
  const matchingServices = data.services ? data.services
    .filter(service => selectedServices.includes(service.id.toString()))
    .map(service => ({ id: service.id, value: service.name })) : []

  const matchingVariables = data.variableDetails ? data.variableDetails.filter(variable =>
    Object.keys(selectedVariableValues).includes(variable.id.toString())
  ) : []

  const variables = matchingVariables
    .map(variable => {
      const value = selectedVariableValues[variable.id]

      if (value && value.length > 0)
        return [variable, value]
      return null
    })
    .filter(item => item !== null)

  const matchingRecipient = selectedRecipients.map(recipient => ({
    id: recipient.value,
    value: recipient.text
  }))

  return (
    <div id="filter-summary-collapse" className="collapse">
      {selectedServices.length > 0 ? <FiltersPreview
        title="Filtre par : Service"
        data={matchingServices}
        stateValue="serviceIds"
      /> : '' }
      {recipientIds.length > 0 && selectedRecipients.length > 0 ? <FiltersPreview
        title="Filtre par : Destinataire"
        data={matchingRecipient}
        stateValue="recipientIds"
      /> : '' }
      {variables.length > 0 ? variables.map(variable => (
        <VariableFiltersPreview key={variables[0][0].id} variable={variable} />
      )) : ''}
    </div>
  )
}

export default FiltersCollapse
