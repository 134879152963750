import React, { useContext, useEffect, useRef, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { PlanningContext } from 'pages/plannings/contexts'

const ServiceFilter = () => {
  const { services } = useContext(PlanningContext)
  const ref = useRef()
  const { state } = useLocation()
  const navigate = useNavigate()

  const serviceIds = state?.serviceIds || services.map(service => service[0])
  const servicesArray = Object.entries(services)

  const onChange = useCallback(event => {
    const { selectedOptions } = event.target
    const selectedServices = Array.from(selectedOptions).map(({ value }) => value)

    navigate(null, { state: { ...state, serviceIds: selectedServices, page: 1 } })
  }, [])

  useEffect(() => {
    $(ref.current).selectpicker('val', state?.serviceIds)
    $(ref.current).trigger('change')
    $(ref.current).on('changed.bs.select', onChange)
    $(ref.current).selectpicker({ dropdownAlignRight: true })

    return () => {
      if (ref.current)
        $(ref.current).off('changed.bs.select', onChange)
    }
  }, [serviceIds])

  return (
    <div className='form-group ml-2'>
      <select
        value={serviceIds}
        ref={ref}
        className='planning-dropdown w-100'
        id='serviceIds'
        data-none-selected-text="Tous les services"
        data-actions-box
        multiple
        onChange={onChange}
      >
        {servicesArray.map(([_index, service]) => (
          <option
            className={`circle-pin-${service.color}`}
            value={service.id}
            key={service.id}
          >
            {service.name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default ServiceFilter
