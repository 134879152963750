import React, { useRef, useContext, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PlanningContext } from 'pages/plannings/contexts'
import { DateRangeList, DateModal } from './daterangecomponent'
import moment from 'moment'
import 'daterangepicker'
import { daterangepickerLocale } from 'locales/daterangepicker'
import calendar from 'images/icons/calendar.svg'

const DateRangeButton = () => {
  const inputRef = useRef()
  const data = useContext(PlanningContext)
  const { state } = useLocation()
  const navigate = useNavigate()

  const [rangeStart, setRangeStart] = useState(state?.rangeStart || data.rangeStart)
  const [rangeEnd, setRangeEnd] = useState(state?.rangeEnd || data.rangeEnd)
  const [rangeTitle, setRangeTitle] = useState(state?.rangeTitle || data.rangeTitle)

  useEffect(() => {
    setRangeStart(state?.rangeStart || data.rangeStart)
    setRangeEnd(state?.rangeEnd || data.rangeEnd)
    setRangeTitle(state?.rangeTitle || data.rangeTitle)
  }, [state, data.rangeStart, data.rangeEnd, data.rangeTitle])

  useEffect(() => {
    $(inputRef.current).daterangepicker({
      startDate: rangeStart,
      endDate: rangeEnd,
      locale: daterangepickerLocale
    }, (start, end) => {
      setRangeStart(moment(start, 'DD-MM-YYYY'))
      setRangeEnd(moment(end, 'DD-MM-YYYY'))
      setRangeTitle('Période personnalisée')
      navigate(null,
        {
          state:
          { ...state,
            rangeTitle: 'Période personnalisée',
            rangeStart: start.format('DD-MM-YYYY'),
            rangeEnd: end.format('DD-MM-YYYY'),
            page: 1
          }
        }
      )
    })
  }, [rangeStart, rangeEnd, navigate, state])

  return (
    <>
      <div className='form-group'>
        <div className="input-group-prepend">
          <button
            type="button"
            className="btn btn-outline-danger dropdown-toggle rounded-left date_range_date_button"
            data-toggle="dropdown"
          >
            {rangeTitle}
          </button>
          <div className="dropdown-menu">
            <DateRangeList />
          </div>
          <div className="input-group-text bg-transparent border-right-0 px-2">
            <img
              className="date-range-custom-icon-calendar"
              src={calendar}
            />
          </div>
          <input
            type="text"
            name="date_range"
            id="date_range"
            ref={inputRef}
            value={
              `${moment(rangeStart, 'DD-MM-YYYY').format('DD/MM/YYYY')}
              -
              ${moment(rangeEnd, 'DD-MM-YYYY').format('DD/MM/YYYY')}`
            }
            className="pl-0 form-control form-control-date-range border-left-0 rounded-right h-100 pr-1"
            placeholder="Période..."
            readOnly
          />
        </div>
      </div>
      <DateModal />
    </>
  )
}

export default DateRangeButton
