import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { AgendaContext } from 'pages/agenda/contexts'
import { updateUser } from 'pages/agenda/actions'
import { ButtonGroup } from 'components'

const AgendaFilter = ({ calendar }) => {
  const { userId, agendaLabel } = useContext(AgendaContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const isActive = type => (state?.agendaLabel || agendaLabel) === type

  const onClick = type => {
    updateUser(state?.userId || userId, { agenda_label: type }).then(({ agenda_label }) =>
      navigate(null, { state: { ...state, agendaLabel: agenda_label } })
    )
  }

  const btns = [
    { key: 'service', value: 'Service', isActive, onClick },
    { key: 'recipient', value: 'Destinataire', isActive, onClick }
  ]

  return (
    calendar === 'list' ? '' : (
      <div className="mb-2">
        <ButtonGroup btns={btns} />
      </div>
    )
  )
}

AgendaFilter.propTypes = {
  calendar: PropTypes.string.isRequired
}

export default AgendaFilter
