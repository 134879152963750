import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { RouterProvider } from 'react-router-dom'
import { PlanningContext } from './contexts'
import router from './router'

const Planning = ({
  services = '{}',
  date_range = '{}',
  variable_details = '{}'
}) => {
  const data = useMemo(() => ({
    services: JSON.parse(services),
    rangeStart: moment().format('DD-MM-YYYY'),
    rangeEnd: moment().add(6, 'months').format('DD-MM-YYYY'),
    rangeTitle: 'Période personnalisée',
    dateRange: JSON.parse(date_range),
    variableDetails: JSON.parse(variable_details),
    recipientIds: [],
    page: 1
  }))

  return (
    <PlanningContext.Provider value={data}>
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </PlanningContext.Provider>
  )
}

Planning.propTypes = {
  date_range: PropTypes.string,
  services: PropTypes.string,
  variable_details: PropTypes.string
}

export default Planning
