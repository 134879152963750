import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from 'components'
import PropTypes from 'prop-types'

const FiltersPreviewItem = ({ item, stateValue }) => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const values = state && state[stateValue] ? state[stateValue] : []

  const onClick = useCallback(() => {
    const updatedStateValue = values.filter(stateId => stateId !== item.id.toString())

    navigate(null, { state: { ...state, [stateValue]: updatedStateValue } })
  }, [stateValue, values])

  return (
    <a className="report-label-list-item rounded" onClick={onClick}>
      {item.value}
      <span className="pl-1">
        <Icon name="times" />
      </span>
    </a>
  )
}

FiltersPreviewItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  stateValue: PropTypes.string.isRequired
}

export default FiltersPreviewItem
