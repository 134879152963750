import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { RecipientItem, AppointmentCell, NewAppointmentItem } from './cellcomponent'

const defaultPlanningData = { recipients: [], services: [], appointments: {} }

const Tablebody = ({ planningData = defaultPlanningData }) => {
  useEffect(() => {
    const handleCellClick = event => {
      event.preventDefault()

      let targetElement = event.target

      while (targetElement && targetElement !== event.currentTarget) {
        if (targetElement.tagName.toLowerCase() === 'a') {
          window.location.href = targetElement.href
          return
        }
        if (targetElement.tagName.toLowerCase() === 'button')
          return

        targetElement = targetElement.parentElement
      }

      const clickedCell = event.currentTarget
      const { recipientId } = clickedCell.dataset
      const { serviceId } = clickedCell.dataset

      const url = `/dashboard/appointment_batches/new?recipient_id=${recipientId}` +
      `&service_id=${serviceId}&type=preset`
      window.location.href = url
    }

    if (window.innerWidth <= 768) {
      document.querySelectorAll('.clickable-cell').forEach(cell => {
        cell.addEventListener('click', handleCellClick)
      })
    }
  }, [planningData])

  return (
    <tbody>
      {planningData.recipients.map(recipient => (
        <tr key={recipient.id}>
          <td className='first-column align-middle'>
            <RecipientItem recipient={recipient} />
          </td>
          {planningData.services.map(service => (
            <td
              key={service.id}
              className='align-middle clickable-cell'
              data-recipient-id={recipient.id}
              data-service-id={service.id}
            >
              {planningData.appointments?.[recipient.id]?.[service.id] ? (
                <AppointmentCell appointments={planningData.appointments[recipient.id][service.id]} />
              ) : '' }
              <NewAppointmentItem recipient_id={recipient.id} service_id={service.id} />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}

Tablebody.propTypes = {
  planningData: PropTypes.shape({
    recipients: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    services: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    appointments: PropTypes.objectOf(
      PropTypes.objectOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            date: PropTypes.string
          })
        )
      )
    )
  })
}

export default Tablebody
