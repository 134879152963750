import React from 'react'
import { Icon } from 'components'
import PropTypes from 'prop-types'

const NewAppointmentItem = ({ recipient_id, service_id }) => {
  const params = `?recipient_id=${recipient_id}&service_id=${service_id}&type=preset`
  const url = `/dashboard/appointment_batches/new${params}`

  return (
    <div className="planning-appointment-item">
      <a className="add-appointment" href={url}>
        <div className="text-center w-100 py-1">
          <Icon name="plus" className="planning-add-appointment-icon p-1" />
        </div>
      </a>
    </div>
  )
}

NewAppointmentItem.propTypes = {
  recipient_id: PropTypes.number.isRequired,
  service_id: PropTypes.number.isRequired
}

export default NewAppointmentItem
