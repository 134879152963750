import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

// Local imports
import { Planning } from './components'
import NotFound from '../../components/NotFound'

const router = createBrowserRouter([
  { path: '/*', element: <NotFound /> },
  { path: 'planning', element: <Planning /> }
], {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_skipActionErrorRevalidation: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true
  }
})

export default router
