import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Loader } from 'components'
import { getPlanning } from 'pages/plannings/actions'
import { planningStructure } from 'pages/plannings/utils/planning'
import { Table, Pagination } from './table'

const PlanningTable = () => {
  const { state } = useLocation()
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [planningData, setPlanningData] = useState({
    services: [],
    recipients: [],
    appointments: [],
    pagination: {}
  })

  const fetchData = useCallback(async() => {
    const data = await getPlanning(state).then(res => planningStructure(res))

    setIsLoading(false)
    setPlanningData(data)
  }, [state])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onPageChange = useCallback(newPage => {
    setPage(newPage)
  }, [])

  return (
    <div className='planning-table'>
      {isLoading ? <Loader /> : <Table planningData={planningData} />}
      {planningData.pagination.pages > 1 && (
        <Pagination
          page={page}
          pages={planningData.pagination.pages}
          onPageChange={onPageChange}
        />
      )}
    </div>
  )
}

export default PlanningTable
