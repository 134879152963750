const formatPlanningData = data => ({
  params: {
    range_start: data?.rangeStart,
    range_end: data?.rangeEnd,
    service_ids: data?.serviceIds?.join('-'),
    recipient_ids: data?.recipientIds?.join('-'),
    variable_values: data?.variableValues,
    page: data?.page
  }
})

export { formatPlanningData }
