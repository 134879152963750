import React from 'react'
import PropTypes from 'prop-types'
import { ServiceItem } from './cellcomponent'

const Tablehead = ({ services }) => (
  <thead>
    <tr>
      <th className='first-row text-center'>Destinataires</th>
      {services.map(service => (
        <th key={service.id} className='first-row text-center'>
          <ServiceItem service={service} />
        </th>
      ))}
    </tr>
  </thead>
)

Tablehead.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
}

export default Tablehead
