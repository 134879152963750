import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const AppointmentItem = ({ appointment }) => {
  const isRecipientMissing = appointment.recipient_missing !== null || appointment.recipient_missing === true
  const isDifferentDays = !moment(appointment.start_at).isSame(appointment.end_at, 'day')
  const startAt = moment(appointment.start_at).format('DD/MM/YYYY')
  const endAt = moment(appointment.end_at).format('DD/MM/YYYY')
  const itemBackgroundClassName = isRecipientMissing ? 'planning-warning' : 'planning-check'
  const itemClassName = `align-self-center px-2 py-2 w-100 ${itemBackgroundClassName}`

  return (
    <div className='planning-appointment-item'>
      <a href={`/appointments/${appointment.id}`}>
        <div className={itemClassName}>
          <b className='appointment-date'>
            {isDifferentDays? `${startAt} - ${endAt}`: startAt}
          </b>
          <div>
            {'De '}
            {moment(appointment.start_at).format('HH:mm')}
            {' à '}
            {moment(appointment.end_at).format('HH:mm')}
          </div>
        </div>
      </a>
    </div>
  )
}

AppointmentItem.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    recipient_missing: PropTypes.string,
    start_at: PropTypes.string.isRequired,
    end_at: PropTypes.string.isRequired
  }).isRequired
}

export default AppointmentItem
