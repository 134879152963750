import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components'
import VariableDetailFilter from './VariableDetailFilter'

const ModalHeader = ({ title }) => (
  <div className="modal-header">
    <h5 className="modal-title" id="variableFilterModalLabel">
      <Icon name="plus" className="pr-1" />
      {title}
    </h5>
    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
)

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired
}

const ModalFooter = ({ closeText }) => (
  <div className="modal-footer">
    <button type="button" className="btn btn-primary" data-dismiss="modal">
      {closeText}
    </button>
  </div>
)

ModalFooter.propTypes = {
  closeText: PropTypes.string.isRequired
}

const VariableModal = () => (
  <div className="modal fade" id="variable-modal" tabIndex="-1" role="dialog">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <ModalHeader title="Ajouter une variable" />
        <div className="modal-body form-group">
          <VariableDetailFilter />
        </div>
        <ModalFooter closeText="Fermer" />
      </div>
    </div>
  </div>
)

export default VariableModal
