import axios from 'axios'
import { toast } from 'react-toastify'
import { formatPlanningData } from './utils'

const getPlanning = (data = {}) =>
  axios.get('/planning/operational', formatPlanningData(data) )
    .then(res => res.data)
    .catch(() => toast.error('Une erreur est survenue'))

export { getPlanning }
