import React from 'react'
import PropTypes from 'prop-types'

const ServiceItem = ({ service }) => (
  <a href={`/services/${service.id}/edit`}>
    {service.name}
  </a>
)

ServiceItem.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
}

export default ServiceItem
