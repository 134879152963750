import React, { useContext, useState, useCallback } from 'react'
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'
import calendar from 'images/icons/calendar.svg'
import 'flatpickr/dist/flatpickr.css'
import { PlanningContext } from 'pages/plannings/contexts'

const DateModal = () => {
  const data = useContext(PlanningContext)
  const [date, setDate] = useState(new Date())
  const { state } = useLocation()
  const navigate = useNavigate()

  const handleDateChange = useCallback(([selectedDate]) => {
    setDate(selectedDate)
    const newState = { ...state, rangeTitle: 'Période personnalisée' }

    if (state.modalOpenWith === 'on') {
      newState.rangeStart = selectedDate
      newState.rangeEnd = moment(selectedDate).add(1, 'days').toDate()
    } else if (state.modalOpenWith === 'before') {
      newState.rangeStart = data.dateRange[0][0].start
      newState.rangeEnd = selectedDate
    } else if (state.modalOpenWith === 'after') {
      newState.rangeStart = selectedDate
      newState.rangeEnd = data.dateRange[0][0].end
    }

    navigate(null, { state: { ...newState, page: 1 } })
  }, [])

  return (
    <div
      className="modal date-modal"
      id="date-picker-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="datePickerModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title my-auto" id="datePickerModalLabel">
              Sélectionner une date
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="input-group border mb-1 date-range-datepicker-input-group">
              <div className="input-group-prepend ml-1">
                <img
                  className="date-range-custom-icon-calendar mx-1"
                  src={calendar}
                  alt="calendar icon"
                />
              </div>
              <input
                className="w-80 form-control border-0 pl-2 date-range-datepicker-custom-input"
                id="date-range-datepicker-custom-input"
                type="text"
                value={moment(date).format('DD/MM/YYYY')}
                readOnly
              />
            </div>
            <Flatpickr
              className="w-80 form-control border-0 pl-2"
              id="date-range-datepicker-input"
              value={date}
              options={{ dateFormat: 'd/m/Y', inline: true, locale: French }}
              onChange={handleDateChange}
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-dismiss="modal">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DateModal
