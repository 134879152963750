import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

// Local imports
import { Calendar, NewAppointment, EditAppointment, EditAppointmentSchedules } from './components'
import NotFound from '../../components/NotFound'

const router = createBrowserRouter([
  { path: '/*', element: <NotFound /> },
  {
    path: '/agenda',
    element: <Calendar />,
    children: [
      { path: 'new_appointment', element: <NewAppointment /> },
      { path: 'edit_appointment/:id', element: <EditAppointment /> },
      { path: 'edit_appointment_schedules/:id', element: <EditAppointmentSchedules /> }
    ]
  }
], {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_skipActionErrorRevalidation: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true
  }
})

export default router
